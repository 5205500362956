@import "../../../utils/variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: lighten($accentColor, 10%);
  margin: -20px -30px;
  padding: 1rem 3rem;
  margin: 0 8px;
  text-align: center;
  font-style: italic;
  border: 1px solid rgba($accentColor, 0.2);
  border-radius: 3px;

  @media(max-width: $mobileBreakpoint) {
    padding: 1rem;
    margin: 0;
    font-size: 0.9rem;
  }

  .tooltip {
    margin-top: 1rem;

    @media(max-width: $mobileBreakpoint) {
      font-size: 2rem;
    }
  }
}

.text {
  color: lighten($accentColor, 10%);
  font-weight: normal;
}
